
import ls from 'localstorage-slim'
import encUTF8 from 'crypto-js/enc-utf8'
import AES from 'crypto-js/aes'
// import tripleDES from 'crypto-js/tripledes'; // to use TDES, replace AES by tripleDES
// import RC4 from 'crypto-js/rc4';             // to use RC4, replace AES by RC4
// import rabbit from 'crypto-js/rabbit';       // to use rabbit, replace AES by rabbit

// update localstorage-slim
ls.config.encrypt = false // global encryption
ls.config.secret = 'secret-string' // global secret

// update encrypter to use AES encryption
ls.config.encrypter = (data, secret) => AES.encrypt(JSON.stringify(data), secret).toString()

// update decrypter to decrypt AES-encrypted data
ls.config.decrypter = (data, secret) => {
  try {
    return JSON.parse(AES.decrypt(data, secret).toString(encUTF8))
  } catch (e) {
    // incorrect/missing secret, return the encrypted data instead
    return data
  }
}

export default ls
